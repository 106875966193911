
<style lang='scss' scoped src='./UserTable.scss'>
</style>

<template src='./UserTable.html'>
</template>

<script>
import Page from '@/components/Page'
import UserCard from '@/components/userCard/UserCard'

export default {
  components: {
    Page,
    UserCard
  },
  props: {
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: function () {
        return 'Nomads'
      }
    },
    filters: {
      type: Object
    }
  },
  methods: {
    changeSortTo: function (sort) {
      if (this.filters['sort'] === sort) {
        this.filters['desc'] = !this.filters['desc']
      } else {
        this.filters['sort'] = sort
        this.filters['desc'] = true
      }
      // reset page to 1 when sorting
      this.filters['page'] = 1
      this.sentFilters()
    },
    sentFilters: function () {
      this.$emit('updateFilters', this.filters)
    },
    onChangePageTo: function (page) {
      this.filters['page'] = page
      this.sentFilters()
    },
    onGetSortFromCard (sort) {
      this.changeSortTo(sort)
    }
  }
}
</script>
