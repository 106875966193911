
<style scoped lang="scss" src="./UserCard.scss"></style>

<template src='./UserCard.html'></template>

<script>
import CollapseBtn from '@/components/CollapseBtn'
import AimPhoneIcon from '@/components/Aim/icons/AimPhoneIcon'
import AimEmailIcon from '@/components/Aim/icons/AimEmailIcon'

export default {
  components: {
    CollapseBtn,
    AimPhoneIcon,
    AimEmailIcon
  },
  props: {
    user: null
  },
  data: function () {
    return {
      isCollapsed: true
    }
  },
  methods: {
    onGetCollapse: function (collapse) {
      this.isCollapsed = collapse
    },
    changeSortTo: function (sort) {
      this.$emit('updateSort', sort)
    }
  }
}
</script>
