<style scoped>
</style>

<template >
  <div class="row mt-4">
    <div class="col-12 mx-auto align-middle">
      <UserTable
        :filters="filters"
        :userInfo="userInfo"
        @updateFilters="onGetFilters($event)"
      >
      </UserTable>
    </div>
  </div>
</template>

<script>
import UserInfoService from '@/services/UserInfoService'
import UserTable from '@/components/userTable/UserTable'
import AimCommonMixin from '@/mixins/aim.common.mixin'

export default {
  name: 'Directory',
  mixins: [AimCommonMixin],
  components: {
    UserTable
  },
  data: function () {
    return {
      filters: {
        directory: true,
        sort: 'namedirectory',
        desc: true,
        size: 0,
        page: 1
      },
      userInfo: {}
    }
  },
  mounted: function () {
    this.searchUser()
  },
  methods: {
    searchUser () {
      this.$updateloading(1)
      let filterStr = this.SerializeFiltersMixin(this.filters)
      UserInfoService.search(filterStr)
        .then((resp) => {
          this.$updateloading(-1)
          this.userInfo = resp.data.userInfo
        }).catch(errors => {
          this.$updateloading(-1)
        })
    },
    onGetFilters (filters) {
      this.filters = filters
      this.searchUser()
    }
  }
}
</script>
